import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/datamigrators.github.io/datamigrators.github.io/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`The carbon theme uses Sass to take advantage of the carbon-components styles and
variables while authoring novel components. In addition, we use css modules to
ensure we don’t collide with devs and make sure our components are portable and
shadowable.`}</p>
    </PageDescription>
    <h2>{`Local Styles`}</h2>
    <p>{`For your application’s local styles, you can just import your style sheet
`}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/docs/global-css/#adding-global-styles-without-a-layout-component"
      }}>{`directly into a `}<inlineCode parentName="a">{`gatsby-browser.js`}</inlineCode></a>{`
file at the root of your project.`}</p>
    <p>{`You can also use sass modules like we do in the theme, this would make it easier
for you to share your component with other theme consumers down the line.`}</p>
    <p>{`Every Sass file in your project automatically has access to the the following
carbon resources:`}</p>
    <ul>
      <li parentName="ul">{`colors – `}<inlineCode parentName="li">{`background: carbon--gray-10;`}</inlineCode></li>
      <li parentName="ul">{`spacing - `}<inlineCode parentName="li">{`margin: $spacing-05;`}</inlineCode></li>
      <li parentName="ul">{`theme-tokens - `}<inlineCode parentName="li">{`color: $text-01;`}</inlineCode></li>
      <li parentName="ul">{`motion -
`}<inlineCode parentName="li">{`transition: all $duration--moderate-01 motion(entrance, productive);`}</inlineCode></li>
      <li parentName="ul">{`typography - `}<inlineCode parentName="li">{`@include carbon--type-style('body-long-01');`}</inlineCode></li>
    </ul>
    <h2>{`Targeting theme components`}</h2>
    <InlineNotification mdxType="InlineNotification">
      <p>{`We reserve the right to change classes between major releases. Use this strategy
at your own risk.`}</p>
    </InlineNotification>
    <p>{`Theme component classes have a hash of their styles tacked on to the end. This
is both to prevent collisions, and also to prevent sneaky breaking changes to
your styles if we update the class underneath you and you were relying on our
styles.`}</p>
    <p>{`However, you can target the classes without the hash by using a
`}<a parentName="p" {...{
        "href": "https://css-tricks.com/almanac/selectors/a/attribute/"
      }}>{`partial selector`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`[class*='Banner-module--column'] {
  color: $text-04;
}
`}</code></pre>
    <p>{`This will match the class that starts with `}<inlineCode parentName="p">{`Banner-module--column`}</inlineCode>{` and would be
immune to any changes to the hash. We may at some point remove the hash if this
becomes an issue.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      